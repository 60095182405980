* {
    box-sizing: border-box;
}

.App {
    width: 100%;
    height: 100%;
    background-color: #1a1e21;
}

.Home {
    padding-top: 30vh;
    padding-bottom: 55vh;
    color: #22223b;
    background-image: url("../../public/Dreamscape.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.Welcome-head {
    text-align: center;
    font-family: "Electrolize", sans-serif;
    font-size: 80px;
    font-weight: 600;
    margin-bottom: -15px;
    color: #22223b;
}

.Welcome-text {
    text-align: center;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-size: 18px;
}

@media only screen and (max-width: 620px) {
    .Welcome-head {
        font-size: 65px;
    }

    .Welcome-text {
        font-size: 15px;
    }
}

@media only screen and (max-width: 430px) {
    .Welcome-head {
        font-size: 54px;
    }

    .Welcome-text {
        font-size: 15px;
        margin: 0 1em;
    }
}

/* Navbar */
a {
    text-decoration: none !important;
    color: #85888b !important;
}

a:hover {
    color: #f7f0f6 !important;
}

.Navbar {
    display: block;
    position: fixed;
    width: fit-content;
    min-height: 100%;
    overflow: hidden;
    z-index: 9999;
    padding: 2.75em 1em 1em 3em;
    background: transparent;
}

.Nav-name {
    font-family: "Electrolize", sans-serif;
    font-weight: 400;
    font-size: 30px;
    padding-bottom: 1px;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}

.Nav-name:hover {
    transform: scale(1.1);
}

.Navlist {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 0px;
    padding-top: 0.75em;
}

.Navlist-links {
    display: block;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-size: 20px;
    transition: all 0.2s ease-in-out;
}
.Navlist-links:hover {
    transform: scale(1.1);
}

.toggle-button {
    display: none;
}

/* Navbar for phone/tablet */
@media only screen and (max-width: 980px) {
    .toggle-button {
        display: flex;
        border: none;
        color: #dde6eb;
        background: none;
    }

    .Navlist {
        display: none;
    }

    .Navlist-active {
        display: flex;
        flex-direction: column;
        gap: 2px;
        padding-left: 0px;
        margin-bottom: 0px;
    }

    .Navbar {
        width: 100%;
        top: 0;
        position: sticky;
        align-items: center;
        padding: 1em 1em 0.5em;
        background-color: #22223b;
    }

    .Navbar button {
        position: absolute;
        top: 1.46rem;
        left: 1rem;
    }

    a {
        color: #dde6eb !important;
    }

    .Nav-name {
        font-weight: 400;
        font-size: 23px;
        text-align: center;
    }

    .Navlist-links {
        text-align: center;
        padding-bottom: 10px;
        font-family: "Lato", sans-serif;
        font-weight: 300;
        font-size: 15px;
    }
}

/* About */
#about {
    display: flex;
    justify-content: center;
}
.About {
    padding-top: 24vh;
    padding-bottom: 28vh;
    width: 50%;
    font-family: "Lato", sans-serif;
    color: #eaeff3;
}

.About h2 {
    font-family: "Electrolize", sans-serif;
    text-align: center;
    padding-bottom: 12px;
}

.About p {
    font-size: 17px;
}

@media only screen and (max-width: 980px) {
    .About p {
        font-size: 15px;
    }
}

@media only screen and (max-width: 430px) {
    .About p {
        font-size: 10.5px;
    }
}

/* Projects */

#portfolio {
    padding-top: 15vh;
    padding-bottom: 30vh;
}

.Projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* .Projects-card {
    padding-left: 3em;
} */

#portfolio h2 {
    font-family: "Electrolize", sans-serif;
    text-align: center;
    color: #eaeff3;
}

.card {
    width: 25%;
    object-fit: cover;
    margin: 2em;
    font-family: "Lato", sans-serif;
}
.card:hover {
    transform: scale(1.08);
    transition: all 0.3s ease;
}
/* .card-img-top {
  height: 100%;
  object-fit:inherit;
} */
.website-btn {
    margin-left: 1em;
}

@media only screen and (max-width: 980px) {
    .Projects {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card {
        width: 50%;
        margin: 2em;
    }
}

@media only screen and (max-width: 430px) {
    .card {
        width: 70%;
        margin: 2em;
    }
}

/* Contact */

#contact {
    padding-top: 5em;
    padding-bottom: 15em;
}

.Contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Lato", sans-serif;
    font-weight: 500;
}

.Contact-title {
    font-family: "Electrolize", sans-serif;
    margin-bottom: 0.5em;
    color: #eaeff3;
}

.Contact img {
    margin-bottom: 1em;
    margin-top: 3em;
    border-style: solid;
    border-width: 4px;
    border-color: black;
}

.Contact ul {
    display: flex;
    flex-direction: row;
    padding-left: 0;
    justify-content: space-between;
}
.Contact li {
    list-style-type: none;
    padding-left: 1.5em;
    padding-right: 1.5em;
    font-size: 1.7em;
}

.contact-form-container {
    color: white;
    margin-left: -13.5em;
}

.contact-form {
    display: flex;
    flex-direction: column;
    width: 210%;
}
.contact-form label {
    font-family: "Lato", sans-serif;
}

.contact-form button {
    font-family: "Electrolize", sans-serif;
}
@media only screen and (max-width: 430px) {
    .contact-form-container {
        margin-left: -5em;
    }
    .contact-form {
        width: 140%;
    }
}
